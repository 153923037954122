import { default as _91_46_46_46slug_9340L8NmrpzCMeta } from "/home/forge/agjenti.al/pages/[...slug].vue?macro=true";
import { default as indexJaDvqeunduMeta } from "/home/forge/agjenti.al/pages/auth/facebook/callback/index.vue?macro=true";
import { default as indexGhD5aaYoV2Meta } from "/home/forge/agjenti.al/pages/auth/gazeta_celesi_al/callback/index.vue?macro=true";
import { default as indexG8pxUOqHSqMeta } from "/home/forge/agjenti.al/pages/auth/instagram/callback/index.vue?macro=true";
import { default as indexk4m0uUb9wDMeta } from "/home/forge/agjenti.al/pages/auth/linkedin/callback/index.vue?macro=true";
import { default as _91token_93Eu1BA0ecA1Meta } from "/home/forge/agjenti.al/pages/auth/reset/password/[token].vue?macro=true";
import { default as indexXbNmDie32RMeta } from "/home/forge/agjenti.al/pages/auth/tiktok/callback/index.vue?macro=true";
import { default as indexLv26YFAWm9Meta } from "/home/forge/agjenti.al/pages/auth/verify/email/index.vue?macro=true";
import { default as indexvVhD6Ni4DgMeta } from "/home/forge/agjenti.al/pages/billing/index.vue?macro=true";
import { default as indexLz93ZUICseMeta } from "/home/forge/agjenti.al/pages/blog/index.vue?macro=true";
import { default as indexpB1UuatT2sMeta } from "/home/forge/agjenti.al/pages/connect-by-extension/index.vue?macro=true";
import { default as index8MbMk21AOLMeta } from "/home/forge/agjenti.al/pages/connect/index.vue?macro=true";
import { default as _91_91id_93_937XgALKm7BhMeta } from "/home/forge/agjenti.al/pages/create/[[id]].vue?macro=true";
import { default as indexy0F7gZ50NdMeta } from "/home/forge/agjenti.al/pages/design-templates/create/index.vue?macro=true";
import { default as indexgePl8csl3FMeta } from "/home/forge/agjenti.al/pages/design-templates/index.vue?macro=true";
import { default as indexp8XlcGO5qzMeta } from "/home/forge/agjenti.al/pages/errors/index.vue?macro=true";
import { default as indexIgiZp5wSGqMeta } from "/home/forge/agjenti.al/pages/forgot/password/index.vue?macro=true";
import { default as _91portal_93v7ph3KPjZDMeta } from "/home/forge/agjenti.al/pages/import/from/[portal].vue?macro=true";
import { default as indexl7lsrqPNbKMeta } from "/home/forge/agjenti.al/pages/import/index.vue?macro=true";
import { default as indexEt70MitW3LMeta } from "/home/forge/agjenti.al/pages/index.vue?macro=true";
import { default as _91slug_93MV8lhuKXDfMeta } from "/home/forge/agjenti.al/pages/listings/[slug].vue?macro=true";
import { default as indexBXKUfe5StAMeta } from "/home/forge/agjenti.al/pages/listings/index.vue?macro=true";
import { default as indexjPRz3aUBcPMeta } from "/home/forge/agjenti.al/pages/login/index.vue?macro=true";
import { default as indexos7dHjG1cTMeta } from "/home/forge/agjenti.al/pages/privacy-policy/index.vue?macro=true";
import { default as indexb3fWO3t9GwMeta } from "/home/forge/agjenti.al/pages/profile/index.vue?macro=true";
import { default as indexWS0U9kpreLMeta } from "/home/forge/agjenti.al/pages/register/index.vue?macro=true";
import { default as index92IkPXY30sMeta } from "/home/forge/agjenti.al/pages/terms-of-service/index.vue?macro=true";
import { default as _91_91username_93_93QKe6ZgtBpeMeta } from "/home/forge/agjenti.al/pages/u/[[username]].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_9340L8NmrpzCMeta?.name ?? "slug",
    path: _91_46_46_46slug_9340L8NmrpzCMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_9340L8NmrpzCMeta || {},
    alias: _91_46_46_46slug_9340L8NmrpzCMeta?.alias || [],
    redirect: _91_46_46_46slug_9340L8NmrpzCMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexJaDvqeunduMeta?.name ?? "auth-facebook-callback",
    path: indexJaDvqeunduMeta?.path ?? "/auth/facebook/callback",
    meta: indexJaDvqeunduMeta || {},
    alias: indexJaDvqeunduMeta?.alias || [],
    redirect: indexJaDvqeunduMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/facebook/callback/index.vue").then(m => m.default || m)
  },
  {
    name: indexGhD5aaYoV2Meta?.name ?? "auth-gazeta_celesi_al-callback",
    path: indexGhD5aaYoV2Meta?.path ?? "/auth/gazeta_celesi_al/callback",
    meta: indexGhD5aaYoV2Meta || {},
    alias: indexGhD5aaYoV2Meta?.alias || [],
    redirect: indexGhD5aaYoV2Meta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/gazeta_celesi_al/callback/index.vue").then(m => m.default || m)
  },
  {
    name: indexG8pxUOqHSqMeta?.name ?? "auth-instagram-callback",
    path: indexG8pxUOqHSqMeta?.path ?? "/auth/instagram/callback",
    meta: indexG8pxUOqHSqMeta || {},
    alias: indexG8pxUOqHSqMeta?.alias || [],
    redirect: indexG8pxUOqHSqMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/instagram/callback/index.vue").then(m => m.default || m)
  },
  {
    name: indexk4m0uUb9wDMeta?.name ?? "auth-linkedin-callback",
    path: indexk4m0uUb9wDMeta?.path ?? "/auth/linkedin/callback",
    meta: indexk4m0uUb9wDMeta || {},
    alias: indexk4m0uUb9wDMeta?.alias || [],
    redirect: indexk4m0uUb9wDMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/linkedin/callback/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Eu1BA0ecA1Meta?.name ?? "auth-reset-password-token",
    path: _91token_93Eu1BA0ecA1Meta?.path ?? "/auth/reset/password/:token()",
    meta: _91token_93Eu1BA0ecA1Meta || {},
    alias: _91token_93Eu1BA0ecA1Meta?.alias || [],
    redirect: _91token_93Eu1BA0ecA1Meta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/reset/password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexXbNmDie32RMeta?.name ?? "auth-tiktok-callback",
    path: indexXbNmDie32RMeta?.path ?? "/auth/tiktok/callback",
    meta: indexXbNmDie32RMeta || {},
    alias: indexXbNmDie32RMeta?.alias || [],
    redirect: indexXbNmDie32RMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/tiktok/callback/index.vue").then(m => m.default || m)
  },
  {
    name: indexLv26YFAWm9Meta?.name ?? "auth-verify-email",
    path: indexLv26YFAWm9Meta?.path ?? "/auth/verify/email",
    meta: indexLv26YFAWm9Meta || {},
    alias: indexLv26YFAWm9Meta?.alias || [],
    redirect: indexLv26YFAWm9Meta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/auth/verify/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexvVhD6Ni4DgMeta?.name ?? "billing",
    path: indexvVhD6Ni4DgMeta?.path ?? "/billing",
    meta: indexvVhD6Ni4DgMeta || {},
    alias: indexvVhD6Ni4DgMeta?.alias || [],
    redirect: indexvVhD6Ni4DgMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexLz93ZUICseMeta?.name ?? "blog",
    path: indexLz93ZUICseMeta?.path ?? "/blog",
    meta: indexLz93ZUICseMeta || {},
    alias: indexLz93ZUICseMeta?.alias || [],
    redirect: indexLz93ZUICseMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexpB1UuatT2sMeta?.name ?? "connect-by-extension",
    path: indexpB1UuatT2sMeta?.path ?? "/connect-by-extension",
    meta: indexpB1UuatT2sMeta || {},
    alias: indexpB1UuatT2sMeta?.alias || [],
    redirect: indexpB1UuatT2sMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/connect-by-extension/index.vue").then(m => m.default || m)
  },
  {
    name: index8MbMk21AOLMeta?.name ?? "connect",
    path: index8MbMk21AOLMeta?.path ?? "/connect",
    meta: index8MbMk21AOLMeta || {},
    alias: index8MbMk21AOLMeta?.alias || [],
    redirect: index8MbMk21AOLMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/connect/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_937XgALKm7BhMeta?.name ?? "create-id",
    path: _91_91id_93_937XgALKm7BhMeta?.path ?? "/create/:id?",
    meta: _91_91id_93_937XgALKm7BhMeta || {},
    alias: _91_91id_93_937XgALKm7BhMeta?.alias || [],
    redirect: _91_91id_93_937XgALKm7BhMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/create/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexy0F7gZ50NdMeta?.name ?? "design-templates-create",
    path: indexy0F7gZ50NdMeta?.path ?? "/design-templates/create",
    meta: indexy0F7gZ50NdMeta || {},
    alias: indexy0F7gZ50NdMeta?.alias || [],
    redirect: indexy0F7gZ50NdMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/design-templates/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexgePl8csl3FMeta?.name ?? "design-templates",
    path: indexgePl8csl3FMeta?.path ?? "/design-templates",
    meta: indexgePl8csl3FMeta || {},
    alias: indexgePl8csl3FMeta?.alias || [],
    redirect: indexgePl8csl3FMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/design-templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8XlcGO5qzMeta?.name ?? "errors",
    path: indexp8XlcGO5qzMeta?.path ?? "/errors",
    meta: indexp8XlcGO5qzMeta || {},
    alias: indexp8XlcGO5qzMeta?.alias || [],
    redirect: indexp8XlcGO5qzMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/errors/index.vue").then(m => m.default || m)
  },
  {
    name: indexIgiZp5wSGqMeta?.name ?? "forgot-password",
    path: indexIgiZp5wSGqMeta?.path ?? "/forgot/password",
    meta: indexIgiZp5wSGqMeta || {},
    alias: indexIgiZp5wSGqMeta?.alias || [],
    redirect: indexIgiZp5wSGqMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/forgot/password/index.vue").then(m => m.default || m)
  },
  {
    name: _91portal_93v7ph3KPjZDMeta?.name ?? "import-from-portal",
    path: _91portal_93v7ph3KPjZDMeta?.path ?? "/import/from/:portal()",
    meta: _91portal_93v7ph3KPjZDMeta || {},
    alias: _91portal_93v7ph3KPjZDMeta?.alias || [],
    redirect: _91portal_93v7ph3KPjZDMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/import/from/[portal].vue").then(m => m.default || m)
  },
  {
    name: indexl7lsrqPNbKMeta?.name ?? "import",
    path: indexl7lsrqPNbKMeta?.path ?? "/import",
    meta: indexl7lsrqPNbKMeta || {},
    alias: indexl7lsrqPNbKMeta?.alias || [],
    redirect: indexl7lsrqPNbKMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexEt70MitW3LMeta?.name ?? "index",
    path: indexEt70MitW3LMeta?.path ?? "/",
    meta: indexEt70MitW3LMeta || {},
    alias: indexEt70MitW3LMeta?.alias || [],
    redirect: indexEt70MitW3LMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MV8lhuKXDfMeta?.name ?? "listings-slug",
    path: _91slug_93MV8lhuKXDfMeta?.path ?? "/listings/:slug()",
    meta: _91slug_93MV8lhuKXDfMeta || {},
    alias: _91slug_93MV8lhuKXDfMeta?.alias || [],
    redirect: _91slug_93MV8lhuKXDfMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/listings/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBXKUfe5StAMeta?.name ?? "listings",
    path: indexBXKUfe5StAMeta?.path ?? "/listings",
    meta: indexBXKUfe5StAMeta || {},
    alias: indexBXKUfe5StAMeta?.alias || [],
    redirect: indexBXKUfe5StAMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/listings/index.vue").then(m => m.default || m)
  },
  {
    name: indexjPRz3aUBcPMeta?.name ?? "login",
    path: indexjPRz3aUBcPMeta?.path ?? "/login",
    meta: indexjPRz3aUBcPMeta || {},
    alias: indexjPRz3aUBcPMeta?.alias || [],
    redirect: indexjPRz3aUBcPMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexos7dHjG1cTMeta?.name ?? "privacy-policy",
    path: indexos7dHjG1cTMeta?.path ?? "/privacy-policy",
    meta: indexos7dHjG1cTMeta || {},
    alias: indexos7dHjG1cTMeta?.alias || [],
    redirect: indexos7dHjG1cTMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexb3fWO3t9GwMeta?.name ?? "profile",
    path: indexb3fWO3t9GwMeta?.path ?? "/profile",
    meta: indexb3fWO3t9GwMeta || {},
    alias: indexb3fWO3t9GwMeta?.alias || [],
    redirect: indexb3fWO3t9GwMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexWS0U9kpreLMeta?.name ?? "register",
    path: indexWS0U9kpreLMeta?.path ?? "/register",
    meta: indexWS0U9kpreLMeta || {},
    alias: indexWS0U9kpreLMeta?.alias || [],
    redirect: indexWS0U9kpreLMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: index92IkPXY30sMeta?.name ?? "terms-of-service",
    path: index92IkPXY30sMeta?.path ?? "/terms-of-service",
    meta: index92IkPXY30sMeta || {},
    alias: index92IkPXY30sMeta?.alias || [],
    redirect: index92IkPXY30sMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91username_93_93QKe6ZgtBpeMeta?.name ?? "u-username",
    path: _91_91username_93_93QKe6ZgtBpeMeta?.path ?? "/u/:username?",
    meta: _91_91username_93_93QKe6ZgtBpeMeta || {},
    alias: _91_91username_93_93QKe6ZgtBpeMeta?.alias || [],
    redirect: _91_91username_93_93QKe6ZgtBpeMeta?.redirect || undefined,
    component: () => import("/home/forge/agjenti.al/pages/u/[[username]].vue").then(m => m.default || m)
  }
]