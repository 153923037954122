import revive_payload_client_4sVQNw7RlN from "/home/forge/agjenti.al/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/agjenti.al/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/agjenti.al/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/agjenti.al/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/agjenti.al/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/agjenti.al/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/agjenti.al/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/forge/agjenti.al/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/forge/agjenti.al/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/agjenti.al/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/agjenti.al/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import auth_ANExhJHvcR from "/home/forge/agjenti.al/plugins/auth.js";
import bus_GHaWeQPCWC from "/home/forge/agjenti.al/plugins/bus.js";
import click_outside_oytHm5ka8v from "/home/forge/agjenti.al/plugins/click-outside.js";
import ga_V7wqDbvyvO from "/home/forge/agjenti.al/plugins/ga.js";
import global_dY5SAi3BsR from "/home/forge/agjenti.al/plugins/global.js";
import img_error_RL61QrGX5P from "/home/forge/agjenti.al/plugins/img-error.js";
import sentry_client_shVUlIjFLk from "/home/forge/agjenti.al/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  auth_ANExhJHvcR,
  bus_GHaWeQPCWC,
  click_outside_oytHm5ka8v,
  ga_V7wqDbvyvO,
  global_dY5SAi3BsR,
  img_error_RL61QrGX5P,
  sentry_client_shVUlIjFLk
]