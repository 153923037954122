export default defineNuxtRouteMiddleware(() => {
    //let defaultLocale = 'en'
    let defaultLocale = 'sq'

    if (process.client) {
        const hostname = window.location.hostname

        if (hostname.includes('agjenti.al')) {
            defaultLocale = 'sq'
        } else if (hostname.includes('thelisting.app')) {
            defaultLocale = 'en'
        }
    }

    const { $i18n } = useNuxtApp()
    if ($i18n) {
        $i18n.setLocale(defaultLocale)
    }
})
