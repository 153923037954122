import {defineStore} from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: undefined,
    }),

    actions: {
        async initAuth() {
            let config = useRuntimeConfig().public
            let response = await useFetch(`${config.api_url}/api/user`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + useCookie('token').value,
                }
            })

            this.user = response.data.value
        },

        updateUser(payload) {
            this.user = payload
        },

        incrementListingsCount() {
            this.user.listings_count++
        }
    },
})
