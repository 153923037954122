import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFacebook, LazyForgotPassword, LazyGazetaCelesiAl, LazyGazetaCelesiAlGray, LazyHomezoneAl, LazyHomezoneAlGray, LazyInstagram, LazyLinkedIn, LazyMerrjep, LazyMerrjepGray, LazyNjoftimeAl, LazyNjoftimeAlGray, LazyNjoftimeCom, LazyNjoftimeFalas, LazyNjoftimeFalasGray, LazyOpenEmail, LazyRezidencaAl, LazyRezidencaAlGray, LazyTikTok, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Facebook", LazyFacebook],
["ForgotPassword", LazyForgotPassword],
["GazetaCelesiAl", LazyGazetaCelesiAl],
["GazetaCelesiAlGray", LazyGazetaCelesiAlGray],
["HomezoneAl", LazyHomezoneAl],
["HomezoneAlGray", LazyHomezoneAlGray],
["Instagram", LazyInstagram],
["LinkedIn", LazyLinkedIn],
["Merrjep", LazyMerrjep],
["MerrjepGray", LazyMerrjepGray],
["NjoftimeAl", LazyNjoftimeAl],
["NjoftimeAlGray", LazyNjoftimeAlGray],
["NjoftimeCom", LazyNjoftimeCom],
["NjoftimeFalas", LazyNjoftimeFalas],
["NjoftimeFalasGray", LazyNjoftimeFalasGray],
["OpenEmail", LazyOpenEmail],
["RezidencaAl", LazyRezidencaAl],
["RezidencaAlGray", LazyRezidencaAlGray],
["TikTok", LazyTikTok],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
