import validate from "/home/forge/agjenti.al/node_modules/nuxt/dist/pages/runtime/validate.js";
import determine_45locale_45global from "/home/forge/agjenti.al/middleware/determineLocale.global.js";
import manifest_45route_45rule from "/home/forge/agjenti.al/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  determine_45locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/agjenti.al/middleware/auth.js"),
  guest: () => import("/home/forge/agjenti.al/middleware/guest.js")
}